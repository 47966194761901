<template>
  <v-dialog :value="clockInMap.showDialog" max-width="700px" @click:outside="close" @keydown.esc="close">
    <v-card>
      <v-card-title>{{ translations.location }} {{ clockInMap.employeeName }}</v-card-title>
      <v-card-text>
        <l-map ref="map" :zoom="zoom" style="width: 100%; height: 400px">
          <l-tile-layer :url="url" />

          <!-- project marker -->
          <l-marker ref="projectMarker" v-if="projectLatLng" :lat-lng="projectLatLng">
            <l-icon icon-url="project-marker.svg" :icon-size="[35, 35]" :icon-anchor="[18, 38]" :popup-anchor="[0, -34]" />
            <l-popup>
              <a href="#" @click="openMap(projectLatLng)">{{ clockInMap.projectName }}</a>
            </l-popup>
          </l-marker>

          <!-- employee in marker -->
          <l-marker ref="employeeInMarker" v-if="employeeInLatLng" :lat-lng="employeeInLatLng">
            <l-popup>
              <a href="#" @click="openMap(employeeInLatLng)">{{ translations.in }}</a>
            </l-popup>
          </l-marker>

          <!-- employee out marker -->
          <l-marker ref="employeeOutMarker" v-if="employeeOutLatLng" :lat-lng="employeeOutLatLng">
            <l-popup @click="openMap(employeeOutLatLng)">
              <a href="#" @click="openMap(employeeOutLatLng)">{{ translations.out }}</a>
            </l-popup>
          </l-marker>
        </l-map>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { translations } from '@/utils/common'
import { LIcon, LMap, LMarker, LPopup, LTileLayer } from 'vue2-leaflet'
import { closeMarkerPopup, mapFitBounds, openMarkerPopup, setMarkerIcon, toLatLng } from '@/utils/common/mapsUtil'

export default {
  name: 'ClockInMap',

  components: {
    LIcon,
    LMap,
    LMarker,
    LPopup,
    LTileLayer
  },

  props: {
    clockInMap: {
      type: Object
    }
  },

  data() {
    return {
      translations: translations,

      projectLatLng: null,
      employeeInLatLng: null,
      employeeOutLatLng: null,

      zoom: 16,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    }
  },

  watch: {
    clockInMap() {
      // convert lat and lng
      this.projectLatLng = toLatLng(this.clockInMap.projectLocation)
      this.employeeInLatLng = toLatLng(this.clockInMap.employeeInLocation)
      this.employeeOutLatLng = toLatLng(this.clockInMap.employeeOutLocation)

      // close all the popups
      closeMarkerPopup(this.$refs.projectMarker)
      closeMarkerPopup(this.$refs.employeeInMarker)
      closeMarkerPopup(this.$refs.employeeOutMarker)

      // set marker and fit bounds
      const self = this
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
      setTimeout(() => {
          setMarkerIcon(self.$refs.projectMarker, 'marker-project')
          setMarkerIcon(self.$refs.employeeInMarker, 'marker-in')
          setMarkerIcon(self.$refs.employeeOutMarker, 'marker-out')
          openMarkerPopup(self.$refs[self.clockInMap.clickOn === 'IN' ? 'employeeInMarker' : 'employeeOutMarker'])
          mapFitBounds(self.$refs.map, [self.projectLatLng, self.employeeInLatLng, self.employeeOutLatLng])
        }, 250
      )
    }
  },

  methods: {
    openMap(latLng) {
      // if we're on iOS, open in Apple Maps, otherwise use Google
      let prefix = (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPad') != -1 || navigator.platform.indexOf('iPod') != -1) ? 'maps' : 'https'
      window.open(prefix + '://maps.google.com/maps?daddr=' + latLng.lat + ',' + latLng.lng + '&amp;ll=')
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>


<style>
.marker-project {
  filter: hue-rotate(-20deg);
}

.marker-in {
  filter: hue-rotate(240deg);
}

.marker-out {
  filter: hue-rotate(140deg);
}

.leaflet-bottom {
  display: none;
}
</style>
