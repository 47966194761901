<template>
  <v-dialog :value="isOpen" @click:outside="$emit('clickOutside')" @keydown.esc="$emit('clickOutside')" max-width="430px">
    <v-card class="pa-2">
      <v-card-title>
        <p class="ma-0" style="font-size: 1.25rem;">{{ translations.managingTimeCardFor }}:</p>
        <p class="ma-0 pl-1" style="font-size: 1.25rem;">
          {{ name }}
        </p>
      </v-card-title>
      <v-card-text>
        <section class="mb-3">
          <v-radio-group v-if="order !== 'projectCategory'" class="ma-0 pa-0 mb-2" v-model="newClockInRadioGroupValue" hide-details row>
            <v-radio :label="translations.project" @click="removeClockInRequestProject" :value="'project'"></v-radio>
            <v-radio :label="translations.category" @click="removeClockInRequestProject" :value="'category'"></v-radio>
            <v-radio :label="translations.maintenance" @click="removeClockInRequestProject" :value="'maintenance'"></v-radio>
            <v-radio v-if="clockInTransferIsEnabled" :label="translations.transfer" @click="removeClockInRequestProject" :value="'transfer'"></v-radio>
          </v-radio-group>
        </section>

        <!-- employee selection -->
        <div v-if="order === 'projectCategory'" class="mb-2">
          <label>{{ translations.employee }}</label>
          <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                dense
                hide-details
                autocomplete="off"
                outlined
                height="35px"
                @input="
                  value => {
                    employeeFilterInputHandler(value);
                  }
                "
                @click:clear="clearEmployeeSearchHandler"
                :value="getEmployeeFilterValue()"
                clearable
              ></v-text-field>
            </template>

            <div style="max-height: 300px" class="scroll-bar">
              <v-list dense>
                <v-list-item v-for="(employee, index) in rowDialogEmployeeOptions.employees" :key="`employee-${employee.id}_${index}`" @click="selectEmployeeHandler(employee)">
                  <v-list-item-content>
                    <v-list-item-title>{{ employee.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!rowDialogEmployeeOptions.loading && rowDialogEmployeeOptions.hasNext">
                  <div class="d-flex align-center justify-center" style="width: 100%">
                    <v-progress-circular v-intersect.quiet="onEmployeeIntersect" indeterminate color="main" size="24"></v-progress-circular>
                  </div>
                </v-list-item>

                <v-list-item v-if="!rowDialogEmployeeOptions.loading && !rowDialogEmployeeOptions.hasNext && rowDialogEmployeeOptions.employees.length === 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
        </div>

        <!-- project selection -->
        <div v-if="(newClockInRadioGroupValue === 'project' || newClockInRadioGroupValue === 'category') && order !== 'projectCategory'" class="report-row mb-2">
          <label>{{ newClockInRadioGroupValue === 'project' ? translations.project : translations.category }}</label>
          <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                dense
                hide-details
                autocomplete="off"
                outlined
                height="35px"
                @input="
                  value => {
                    projectFilterInputHandler(value);
                  }
                "
                @click:clear="clearSearchHandler"
                :value="getProjectFilterValue()"
                clearable
              ></v-text-field>
            </template>

            <div v-if="newClockInRadioGroupValue === 'project'" style="max-height: 300px" class="scroll-bar">
              <v-list dense>
                <v-list-item v-for="(project, index) in rowDialogProjectOptions.projects" :key="`project-${project.id}_${index}`" @click="selectProjectHandler(project, 'projects')">
                  <v-list-item-content>
                    <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                    <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!rowDialogProjectOptions.loading && rowDialogProjectOptions.hasNext">
                  <div class="d-flex align-center justify-center" style="width: 100%">
                    <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                  </div>
                </v-list-item>

                <v-list-item v-if="!rowDialogProjectOptions.loading && !rowDialogProjectOptions.hasNext && rowDialogProjectOptions.projects.length === 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>

            <div v-else-if="newClockInRadioGroupValue === 'category'" style="max-height: 300px" class="scroll-bar">
              <v-list dense>
                <v-list-item v-for="(project, index) in rowDialogCategoryOptions.projects" :key="`project-${project.id}_${index}`" @click="selectProjectHandler(project, 'categories')">
                  <v-list-item-content>
                    <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                    <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!rowDialogCategoryOptions.loading && rowDialogCategoryOptions.hasNext">
                  <div class="d-flex align-center justify-center" style="width: 100%">
                    <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                  </div>
                </v-list-item>

                <v-list-item v-if="!rowDialogCategoryOptions.loading && !rowDialogCategoryOptions.hasNext && rowDialogCategoryOptions.projects.length === 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
          <p v-if="newClockInRequest.project && newClockInRequest.project.label" class="pa-0 ma-0 pt-1 grey--text" style="font-size: 14px">
            {{ newClockInRequest.project.label }}
          </p>
        </div>

        <!-- part selection -->
        <div v-if="newClockInRadioGroupValue === 'project' || (order === 'projectCategory' && !project.isCategory)" class="d-flex">
          <div class="report-row mb-2 mr-2">
            <label>{{ translations.part }}</label>
            <v-select v-model="newClockInRequest.part" :disabled="!dialogProjectParts" :items="dialogProjectParts" item-text="keyCode" clearable dense style="font-size: 14px" outlined append-icon="" height="35px" hide-details return-object>
              <template v-slot:item="data">
                <span>{{ `${data.item.keyCode} - ${data.item.description}` }}</span>
              </template>
            </v-select>
            <p v-if="newClockInRequest.part && newClockInRequest.part.description" class="pa-0 ma-0 pt-1 grey--text" style="font-size: 14px">
              {{ newClockInRequest.part.description }}
            </p>
          </div>

          <div class="report-row mb-2">
            <label>{{ translations.subpart }}</label>
            <v-text-field v-model="newClockInRequest.subpart" outlined hide-details height="35px"></v-text-field>
          </div>
        </div>

        <!-- maintenance -->
        <div v-if="newClockInRadioGroupValue === 'maintenance'" class="report-row mb-2">
          <div v-for="(item, index) in newClockInRequest.clockInItemsMaintenance" :key="index">
            <label>{{ translations.maintenanceVehicleCode }}</label>
            <div class="d-flex align-center">
              <v-text-field outlined hide-details class="mr-1" v-model.trim="item.vehicleCode" style="max-width: 150px;"></v-text-field>
              <v-btn v-if="newClockInRequest.clockInItemsMaintenance.length > 1" @click="deleteClockInItemMaintenance(index)" x-small icon class="mr-1" color="error" width="23px" height="23px">
                <v-icon size="13px">mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn v-if="index === newClockInRequest.clockInItemsMaintenance.length - 1" @click="addNewClockInItemMaintenance" x-small fab width="23px" height="23px" depressed>
                <v-icon size="13px">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <!-- clock in/out times -->
        <div class="report-row">
          <label>{{ translations.type }} </label>
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.in" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockInTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>
        <div class="mt-2 mb-4 report-row">
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.out" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockOutTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>

        <div v-if="showNextDayOutOnDialog" class="mb-2">
          <v-alert dense text type="warning" class="ma-0">{{ translations.shiftWillEndNextDay }}</v-alert>
        </div>

        <div>
          <!-- Reference date -->
          <section class="mb-3">
            <div>
              <label>{{ translations.referenceTurn }}</label>

              <div class="report-row">
                <v-select v-model="newClockInRequest.referenceDate" :items="referenceDateOptions" style="font-size: 12px; width: 150px" outlined hide-details append-icon="" dense height="25px"></v-select>
              </div>
            </div>
          </section>
        </div>

        <div class="mt-10 d-flex justify-end">
          <v-btn depressed color="main" :loading="newClockInLoader" :dark="!disableSubmitNewClockInClockOutButton" :disabled="disableSubmitNewClockInClockOutButton" @click="submitNewClockInClockOut">{{ translations.confirm }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { translations } from '@/utils/common'
import moment from 'moment'

import { getClockInTransferIsEnabled } from '@/utils/api/config'

export default {
  name: 'NewClockInClockOutDialog',

  props: {
    date: {
      type: String
    },
    employeeName: {
      type: String
    },
    project: {
      type: Object,
      default: () => {
      }
    },
    rowDialogProjectOptions: {
      type: Object,
      default: () => {
      }
    },
    rowDialogCategoryOptions: {
      type: Object,
      default: () => {
      }
    },
    rowDialogEmployeeOptions: {
      type: Object,
      default: () => {
      }
    },
    newClockInLoader: {
      type: Boolean
    },
    isOpen: {
      type: Boolean
    },
    lunchBreakOptions: {
      type: Array
    },
    referenceDateOptions: {
      type: Array
    },
    orderBy: {
      type: String
    }
  },

  data: () => ({
    newClockInRequest: {
      employee: null,
      part: null,
      project: null,
      clockInTimeData: {
        HH: '00',
        mm: '00'
      },
      clockOutTimeData: {
        HH: '00',
        mm: '00'
      },
      clockInItemsMaintenance: [
        {
          vehicleCode: null
        }
      ],
      clockInItemsRefuel: [],
      layoff: false,
      milling: false,
      van: false,
      vanCode: null,
      lunchTime: 0,
      referenceDate: null,
      transfer: false
    },
    newClockInRadioGroupValue: 'project',
    driveVehicles: false,
    rules: {
      isTimeFormat: value => {
        const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        return pattern.test(value)
      },
      isHour: value => {
        const pattern = /^(?!0$)\d+(?:[,.][05])?$/
        return pattern.test(value)
      }
    },
    order: null
  }),

  created() {
    this.order = this.orderBy
    this.name = this.employeeName ? this.employeeName : this.project.keyCode
    this.isCategory = this.projectIsCategory
    this.newClockInRequest.project = this.order === 'projectCategory' ? this.project : null
  },

  mounted() {
    this.newClockInRequest.referenceDate = moment(this.date).format('yyyy-MM-DD')
  },

  methods: {
    addNewClockInItemRefuel() {
      this.newClockInRequest.clockInItemsRefuel.push({
        hour: 1,
        id: (Math.random() * 100).toFixed(3),
        quantity: 0,
        vehicleCode: ''
      })
      this.$refs.radioNo.$el.click()
    },

    addNewClockInItemMaintenance() {
      this.newClockInRequest.clockInItemsMaintenance.push({
        vehicleCode: null
      })
    },

    deleteClockInItemRefuel(id) {
      const index = this.newClockInRequest.clockInItemsRefuel.findIndex(a => a.id === id)
      this.newClockInRequest.clockInItemsRefuel.splice(index, 1)
    },

    deleteClockInItemMaintenance(index) {
      this.newClockInRequest.clockInItemsMaintenance.splice(index, 1)
    },

    removeClockInRequestProject() {
      //save for now
      // this.newClockInRequest.project = null;
      this.newClockInRequest.part = null
    },

    searchProject(item, queryText) {
      const filterKeyCode = item.keyCode.toLowerCase()
      const filterLabel = item.label.toLowerCase()
      const searchText = queryText.toLowerCase()
      return filterKeyCode.indexOf(searchText) > -1 || filterLabel.indexOf(searchText) > -1
    },

    submitNewClockInClockOut() {
      this.$emit('onSubmit', {
        newClockInRequest: this.newClockInRequest,
        newClockInType: this.newClockInRadioGroupValue
      })
      this.newClockInRequest = {
        employee: null,
        part: null,
        project: null,
        clockInTimeData: {
          HH: '00',
          mm: '00'
        },
        clockOutTimeData: {
          HH: '00',
          mm: '00'
        },
        clockInItemsRefuel: [],
        layoff: false,
        milling: false,
        van: false,
        vanCode: null,
        lunchTime: 60,
        referenceDate: null,
        transfer: false
      }
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.newClockInRadioGroupValue === 'project') {
          return this.$emit('fetch-more-projects')
        } else {
          return this.$emit('fetch-more-categories')
        }
      }
    },

    getProjectFilterValue() {
      return this.newClockInRequest?.project?.keyCode ? this.newClockInRequest?.project?.keyCode : ''
    },

    projectFilterInputHandler(value) {
      if (!value) this.newClockInRequest.project = null

      this.$emit('set-project-list-filter', { value, property: 'rowDialogProjectOptions' })
      this.$emit('set-project-category-list-filter', { value, property: 'rowDialogCategoryOptions' })
    },

    selectProjectHandler(project, type) {
      this.newClockInRequest.project = project
      this.$emit('set-active-menu-list', type)

      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogProjectOptions' })
      this.$emit('set-project-category-list-filter', { value: '', property: 'rowDialogCategoryOptions' })
    },

    clearSearchHandler() {
      this.newClockInRequest.project = null
      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogProjectOptions' })
      this.$emit('set-project-category-list-filter', { value: '', property: 'rowDialogCategoryOptions' })
    },

    onEmployeeIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-employees')
      }
    },

    getEmployeeFilterValue() {
      return this.newClockInRequest?.employee?.name ? this.newClockInRequest?.employee?.name : ''
    },

    employeeFilterInputHandler(value) {
      if (!value) this.newClockInRequest.employee = null

      this.$emit('set-employee-list-filter', { value, property: 'rowDialogEmployeeOptions' })
    },

    selectEmployeeHandler(employee) {
      this.newClockInRequest.employee = employee
      this.$emit('set-employee-list-filter', { value: '', property: 'rowDialogEmployeeOptions' })
    },

    clearEmployeeSearchHandler() {
      this.newClockInRequest.employee = null
      this.$emit('set-employee-list-filter', { value: '', property: 'rowDialogEmployeeOptions' })
    }
  },

  computed: {
    translations: () => translations,

    showNextDayOutOnDialog() {
      const clockInTime = Number(this.newClockInRequest.clockInTimeData.HH)
      const clockOutTime = Number(this.newClockInRequest.clockOutTimeData.HH)

      if (clockOutTime === 0) return false

      return clockInTime > clockOutTime
    },

    dialogProjectParts() {
      return this.newClockInRequest?.project?.parts
    },

    disableSubmitNewClockInClockOutButton() {
      const missingProject = !this.newClockInRequest?.project?.id ? true : false
      const missingEmployee = !this.newClockInRequest?.employee?.id ? true : false

      if (!this.rules.isTimeFormat(this.newClockInRequest.clockInTimestamp) || (this.newClockInRequest.clockOutTimestamp && !this.rules.isTimeFormat(this.newClockInRequest.clockOutTimestamp))) return true

      if (this.order === 'project') {
        if (!missingEmployee) return false
        return true
      }

      if (this.newClockInRequest.milling && !this.rules.isHour(this.newClockInRequest.millingHours)) return true

      if (this.newClockInRequest.van && !this.newClockInRequest.vanCode) return true

      if (this.newClockInRadioGroupValue === 'maintenance' && !this.newClockInRequest.clockInItemsMaintenance.some(ci => !ci.vehicleCode)) {
        return false
      }

      if (this.newClockInRadioGroupValue === 'transfer') {
        return false
      }

      if (!missingProject && ((this.newClockInRequest.lunchTime !== null && this.newClockInRequest.lunchTime >= 0) || this.hasLunchTime)) return false

      return true
    },

    clockInTransferIsEnabled() {
      return getClockInTransferIsEnabled()
    }
  }
}
</script>
