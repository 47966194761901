import { latLng } from 'leaflet'

export function toLatLng(location) {
  if (location) {
    const lj = JSON.parse(location)
    return latLng(lj.latitude, lj.longitude)
  }
  return null
}

export function setMarkerIcon(marker, icon) {
  if (marker) {
    marker.mapObject._icon.classList.add(icon)
  }
}

export function openMarkerPopup(marker) {
  if (marker) {
    marker.mapObject.openPopup()
  }
}

export function closeMarkerPopup(marker) {
  if (marker) {
    marker.mapObject.closePopup()
  }
}

export function mapFitBounds(map, items) {
  if (map) {
    setTimeout(() => map.mapObject.fitBounds(items, { padding: [50, 50] }), 250)
  }
}
