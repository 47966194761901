<template>
  <svg id="a717a37e-e407-426a-be0b-43d4b21f64ff" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" :width="width" viewBox="0 0 516.17136 464.01932">
    <path
      d="M605.94267,488.40586l-112.51752,1.97871a13.508,13.508,0,1,1-.475-27.01176l112.51779-1.97825a6.326,6.326,0,0,1,6.436,6.21385l.25243,14.36175a6.326,6.326,0,0,1-5.791,6.41438Q606.15537,488.402,605.94267,488.40586Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#FDDA9F"
    />
    <path
      d="M584.2132,492.0765l-92.31126,4.214a6.32533,6.32533,0,0,1-6.60785-6.03028l-.97687-21.40108a6.32615,6.32615,0,0,1,6.03075-6.6079l92.31126-4.214a6.32534,6.32534,0,0,1,6.60786,6.03028l.97687,21.40108a6.334,6.334,0,0,1-5.79216,6.59282C584.37295,492.068,584.293,492.0732,584.2132,492.0765Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#2f2e41"
    />
    <path
      d="M428.10761,675.68388V555.967a6.33294,6.33294,0,0,1,6.32578-6.32577h14.36415a6.33294,6.33294,0,0,1,6.32577,6.32577V675.68388a6.333,6.333,0,0,1-6.32577,6.32578H434.43339A6.333,6.333,0,0,1,428.10761,675.68388Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#FDDA9F"
    />
    <path
      d="M465.80205,675.68388V555.967a6.33294,6.33294,0,0,1,6.32578-6.32577H486.492a6.33294,6.33294,0,0,1,6.32577,6.32577V675.68388a6.333,6.333,0,0,1-6.32577,6.32578H472.12783A6.333,6.333,0,0,1,465.80205,675.68388Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#FDDA9F"
    />
    <circle cx="127.63907" cy="157.08023" r="49.63301" fill="#FDDA9F" />
    <path
      d="M496.41941,392.017a11.77114,11.77114,0,0,0,4.29851-2.52322,7.91545,7.91545,0,0,0,2.31377-6.26641,5.32492,5.32492,0,0,0-2.36735-4.09821c-1.77491-1.13854-4.1488-1.14191-6.5658-.07675l.09149-19.376-1.94626-.00927-.10766,22.77856,1.49969-.94336c1.739-1.09206,4.22258-1.86075,5.97745-.73479a3.41953,3.41953,0,0,1,1.48076,2.646,5.98144,5.98144,0,0,1-1.7204,4.67226c-2.14921,2.053-5.28486,2.695-8.86064,3.26875l.30847,1.92159A32.0793,32.0793,0,0,0,496.41941,392.017Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#2f2e41"
    />
    <rect x="503.94366" y="359.37532" width="10.48295" height="1.94615" transform="translate(-384.71076 -148.22206) rotate(-7.5232)" fill="#2f2e41" />
    <rect x="471.13969" y="363.70646" width="10.48295" height="1.94615" transform="translate(-385.56021 -152.47972) rotate(-7.5232)" fill="#2f2e41" />
    <path
      d="M364.25383,371.15168l71.26132,87.0973a13.50742,13.50742,0,1,1-20.90827,17.10689l-71.26174-87.097a6.32522,6.32522,0,0,1,.88989-8.90145l11.117-9.09607a6.32631,6.32631,0,0,1,8.90178.89031Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#FDDA9F"
    />
    <path
      d="M388.02026,390.5906l58.516,71.51947a6.32524,6.32524,0,0,1-.88989,8.90146l-18.98843,15.536a6.32525,6.32525,0,0,1-8.90156-.8902l-58.51624-71.519a6.333,6.333,0,0,1,.88989-8.90146l18.98864-15.53643a6.326,6.326,0,0,1,8.90157.89021Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#2f2e41"
    />
    <path
      id="b687333e-18de-4d8a-8832-7c3edcdf36cf"
      data-name="Path 336"
      d="M793.71626,303.64477a9.42687,9.42687,0,0,0-8.6207-5.76779H613.02293a9.33271,9.33271,0,0,0-9.3277,9.3277V524.89257a9.3327,9.3327,0,0,0,9.3277,9.32769H785.10177a9.3401,9.3401,0,0,0,9.32773-9.32769V307.20468a9.16075,9.16075,0,0,0-.707-3.55991Zm-1.36439,221.2478a7.265,7.265,0,0,1-7.25624,7.25627H613.02293a7.2575,7.2575,0,0,1-7.25628-7.255V307.20593a7.26619,7.26619,0,0,1,7.25628-7.25627H785.10177a7.29229,7.29229,0,0,1,6.73529,4.56461,8.04482,8.04482,0,0,1,.27285.81866,7.19494,7.19494,0,0,1,.24809,1.873Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#3f3d56"
    />
    <path
      id="eca15e8c-c9ce-4149-9dd0-d1ea6c849243"
      data-name="Path 337"
      d="M757.80705,349.27241H705.97631a4.14662,4.14662,0,1,1,0-8.29323h51.83074a4.14662,4.14662,0,1,1,0,8.29323Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#3f3d56"
    />
    <path
      id="b9f37388-2aff-4ffa-881f-18e269279f19"
      data-name="Path 338"
      d="M757.80705,371.04122H705.97631a4.14662,4.14662,0,1,1,0-8.29323h51.83074a4.14662,4.14662,0,1,1,0,8.29323Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#3f3d56"
    />
    <path
      id="ab29eed0-504d-400c-b1ab-2256c8d671ed"
      data-name="Path 339"
      d="M679.38237,383.43638H641.50469a4.67,4.67,0,0,1-4.66507-4.66508V334.07271a4.67,4.67,0,0,1,4.66507-4.66508h37.87768a4.67,4.67,0,0,1,4.66508,4.66508V378.7713A4.67,4.67,0,0,1,679.38237,383.43638Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#FDDA9F"
    />
    <path
      id="fa5fec34-0c55-4409-8f72-9659063fc84a"
      data-name="Path 340"
      d="M757.44736,415.61577H640.31019a4.14662,4.14662,0,0,1,0-8.29324H757.44736a4.14662,4.14662,0,1,1,0,8.29324Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#ccc"
    />
    <path
      id="a43251d6-16e2-4557-b881-b3d221f9e0a0"
      data-name="Path 341"
      d="M757.44736,437.38454H640.31019a4.14662,4.14662,0,0,1,0-8.29323H757.44736a4.14662,4.14662,0,1,1,0,8.29323Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#ccc"
    />
    <path
      id="b1a9b5d8-150e-43cf-a101-e2528d51164c"
      data-name="Path 342"
      d="M757.44736,459.15331H640.31019a4.14662,4.14662,0,0,1,0-8.29323H757.44736a4.14662,4.14662,0,1,1,0,8.29323Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#ccc"
    />
    <path
      id="fb13d629-1d1d-4a08-ba0c-67ba8ed3b5d8"
      data-name="Path 343"
      d="M757.44736,480.92212H640.31019a4.14662,4.14662,0,0,1,0-8.29323H757.44736a4.14662,4.14662,0,1,1,0,8.29323Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#ccc"
    />
    <path
      id="f81dc734-d8e2-4a40-b2eb-9530c4619ee2"
      data-name="Path 344"
      d="M757.44736,502.6909H640.31019a4.14662,4.14662,0,0,1,0-8.29323H757.44736a4.14662,4.14662,0,1,1,0,8.29323Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#ccc"
    />
    <circle id="bb95e310-3287-4bad-95b8-3adafab6ad5a" data-name="Ellipse 44" cx="456.80941" cy="59.36195" r="59.36195" fill="#FDDA9F" />
    <circle cx="455.66008" cy="93.23712" r="6.98485" fill="#fff" />
    <path
      d="M796.82618,238.34254a20.21319,20.21319,0,0,0-20.00969,19.86376c-.00143.10078-.0021.80941-.002,1.73623a5.12443,5.12443,0,0,0,5.12383,5.12415h0a5.12758,5.12758,0,0,0,5.12645-5.1287v-.00331c-.00052-.63047-.00084-1.06684-.00084-1.09363a9.71812,9.71812,0,1,1,14.95978,8.1813l.00178.00216s-7.29285,4.695-9.51624,11.1356l.00189.00045a14.27355,14.27355,0,0,0-.58818,4.06457c0,.56568.03328,5.51521.09758,9.53246a5.28215,5.28215,0,0,0,5.28269,5.19648h0a5.28357,5.28357,0,0,0,5.28336-5.28378l-.0001-.0324c-.02233-3.67906-.03441-7.963-.03441-8.27392,0-3.95475,3.81513-7.89512,6.949-10.46474a21.07651,21.07651,0,0,0,7.24861-11.54006,13.17417,13.17417,0,0,0,.37951-2.85976A20.15739,20.15739,0,0,0,796.97181,238.342Q796.899,238.342,796.82618,238.34254Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#fff"
    />
    <path
      d="M457.11062,353.11069c8.88642-5.842,20.05474-3.21385,29.93149-2.45068,8.92449.68958,19.5023.01017,25.77919-7.2812,6.53025-7.58569,5.98456-18.84844.578-26.87193-5.32164-7.89748-14.15542-12.88423-23.50076-14.1991a37.70238,37.70238,0,0,0-29.29987,8.09134l3.17621,1.31563c-1.851-17.695-14.86116-33.69141-32.31665-38.03043-17.66126-4.39017-37.412,3.10249-46.83455,18.84972-9.28916,15.52433-8.18872,37.18691,5.182,50.08791,6.42115,6.19559,15.15575,9.35193,24.02,7.66768,8.17512-1.55331,15.43892-7.51289,16.96881-15.92869,1.41822-7.80151-1.6799-16.66287-9.2684-20.07a15.13026,15.13026,0,0,0-13.22445.21132,12.85459,12.85459,0,0,0-6.5184,10.28271,11.36326,11.36326,0,0,0,1.20921,5.93593c1.05343,2.15034,4.26374.26647,3.21311-1.87814a8.62848,8.62848,0,0,1,1.78263-9.47043c2.7584-2.88153,7.11274-3.36015,10.7781-2.182,6.70689,2.15576,9.66816,9.84976,8.40431,16.37686-1.41563,7.311-7.657,12.00879-14.76363,13.21166-7.72838,1.30811-15.265-1.95524-20.62979-7.4438-11.23529-11.49445-12.20755-30.00771-4.62853-43.72639,7.68866-13.91713,24.08941-21.39016,39.71144-19.05571,15.4477,2.30839,28.16974,14.4586,32.13382,29.44033a37.70213,37.70213,0,0,1,1.0598,5.72144,1.8891,1.8891,0,0,0,3.17621,1.31563c13.19992-10.96276,35.1547-9.78694,45.97811,4.00445,5.30794,6.76348,6.87223,17.2663.67378,24.06039-5.902,6.46913-15.70329,6.47566-23.76434,5.76714-10.31193-.90635-21.648-3.03723-30.885,3.03522-1.99283,1.3101-.13072,4.53375,1.87815,3.21311Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#2f2e41"
    />
    <path
      d="M497.13311,597.71487H422.7099a5.58785,5.58785,0,0,1-5.58174-5.58174V567.32539a5.58785,5.58785,0,0,1,5.58174-5.58174h74.42321a5.58785,5.58785,0,0,1,5.58174,5.58174v24.80774A5.58785,5.58785,0,0,1,497.13311,597.71487Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#2f2e41"
    />
    <path
      d="M499.67619,475.40655c-2.33019-11.91632-11.37993-21.35307-23.12582-25.23321,4.24165-1.36485,6.93849-3.38593,6.93849-5.64625,0-4.11029-8.88545-7.44232-19.84619-7.44232s-19.84619,3.332-19.84619,7.44232c0,2.29333,2.77013,4.34257,7.11943,5.70772-18.00779,5.36885-31.07841,21.04085-31.07841,39.49584,0,0-5.54321,35.53515-9.89547,63.94079-2.78443,18.17276,12.42946,34.41589,32.22909,34.41589h69.63344c4.56136,0,8.27215-3.44013,8.27215-7.66883C520.07671,580.4185,507.24555,514.11594,499.67619,475.40655Z"
      transform="translate(-341.91432 -217.99034)"
      fill="#2f2e41"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "100%"
    }
  }
};
</script>
